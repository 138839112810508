var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a-row",
    { attrs: { gutter: 10 } },
    [
      _c(
        "a-col",
        { attrs: { md: 12, sm: 24 } },
        [
          _c(
            "a-card",
            { attrs: { bordered: false } },
            [
              _c(
                "a-row",
                { staticStyle: { "margin-left": "14px" } },
                [
                  _c(
                    "a-button",
                    {
                      attrs: { icon: "plus", type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.handleAdd(1)
                        },
                      },
                    },
                    [_vm._v("添加部门")]
                  ),
                  _c(
                    "a-button",
                    {
                      attrs: { icon: "plus", type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.handleAdd(2)
                        },
                      },
                    },
                    [_vm._v("添加下级")]
                  ),
                  _c(
                    "a-button",
                    {
                      attrs: { type: "primary", icon: "download" },
                      on: {
                        click: function ($event) {
                          return _vm.handleExportXls("部门信息")
                        },
                      },
                    },
                    [_vm._v("导出")]
                  ),
                  _c(
                    "a-upload",
                    {
                      attrs: {
                        name: "file",
                        showUploadList: false,
                        multiple: false,
                        headers: _vm.tokenHeader,
                        action: _vm.importExcelUrl,
                      },
                      on: { change: _vm.handleImportExcel },
                    },
                    [
                      _c(
                        "a-button",
                        { attrs: { type: "primary", icon: "import" } },
                        [_vm._v("导入")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-button",
                    {
                      attrs: { title: "删除多条数据", type: "default" },
                      on: { click: _vm.batchDel },
                    },
                    [_vm._v("批量删除")]
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticStyle: {
                    background: "#fff",
                    "padding-left": "16px",
                    height: "100%",
                    "margin-top": "5px",
                  },
                },
                [
                  _c("a-alert", { attrs: { type: "info", showIcon: true } }, [
                    _c("div", { attrs: { slot: "message" }, slot: "message" }, [
                      _vm._v("\n            当前选择："),
                      this.currSelected.title
                        ? _c("span", [
                            _vm._v(_vm._s(_vm.getCurrSelectedTitle())),
                          ])
                        : _vm._e(),
                      this.currSelected.title
                        ? _c(
                            "a",
                            {
                              staticStyle: { "margin-left": "10px" },
                              on: { click: _vm.onClearSelected },
                            },
                            [_vm._v("取消选择")]
                          )
                        : _vm._e(),
                    ]),
                  ]),
                  _c("a-input-search", {
                    staticStyle: { width: "100%", "margin-top": "10px" },
                    attrs: { placeholder: "请输入部门名称" },
                    on: { search: _vm.onSearch },
                  }),
                  _c(
                    "a-col",
                    { attrs: { md: 10, sm: 24 } },
                    [
                      [
                        _c(
                          "a-dropdown",
                          {
                            attrs: { trigger: [this.dropTrigger] },
                            on: { visibleChange: _vm.dropStatus },
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { "user-select": "none" } },
                              [
                                _c("a-tree", {
                                  attrs: {
                                    checkable: "",
                                    multiple: "",
                                    selectedKeys: _vm.selectedKeys,
                                    checkedKeys: _vm.checkedKeys,
                                    treeData: _vm.departTree,
                                    checkStrictly: _vm.checkStrictly,
                                    expandedKeys: _vm.iExpandedKeys,
                                    autoExpandParent: _vm.autoExpandParent,
                                  },
                                  on: {
                                    select: _vm.onSelect,
                                    check: _vm.onCheck,
                                    rightClick: _vm.rightHandle,
                                    expand: _vm.onExpand,
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "a-menu",
                              { attrs: { slot: "overlay" }, slot: "overlay" },
                              [
                                _c(
                                  "a-menu-item",
                                  {
                                    key: "1",
                                    attrs: { icon: "plus" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleAdd(3)
                                      },
                                    },
                                  },
                                  [_vm._v("添加")]
                                ),
                                _c(
                                  "a-menu-item",
                                  { key: "2", on: { click: _vm.handleDelete } },
                                  [_vm._v("删除")]
                                ),
                                _c(
                                  "a-menu-item",
                                  { key: "3", on: { click: _vm.closeDrop } },
                                  [_vm._v("取消")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "drawer-bootom-button" },
            [
              _c(
                "a-dropdown",
                { attrs: { trigger: ["click"], placement: "topCenter" } },
                [
                  _c(
                    "a-menu",
                    { attrs: { slot: "overlay" }, slot: "overlay" },
                    [
                      _c(
                        "a-menu-item",
                        {
                          key: "1",
                          on: {
                            click: function ($event) {
                              return _vm.switchCheckStrictly(1)
                            },
                          },
                        },
                        [_vm._v("父子关联")]
                      ),
                      _c(
                        "a-menu-item",
                        {
                          key: "2",
                          on: {
                            click: function ($event) {
                              return _vm.switchCheckStrictly(2)
                            },
                          },
                        },
                        [_vm._v("取消关联")]
                      ),
                      _c(
                        "a-menu-item",
                        { key: "3", on: { click: _vm.checkALL } },
                        [_vm._v("全部勾选")]
                      ),
                      _c(
                        "a-menu-item",
                        { key: "4", on: { click: _vm.cancelCheckALL } },
                        [_vm._v("取消全选")]
                      ),
                      _c(
                        "a-menu-item",
                        { key: "5", on: { click: _vm.expandAll } },
                        [_vm._v("展开所有")]
                      ),
                      _c(
                        "a-menu-item",
                        { key: "6", on: { click: _vm.closeAll } },
                        [_vm._v("合并所有")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-button",
                    [
                      _vm._v("\n          树操作 "),
                      _c("a-icon", { attrs: { type: "up" } }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "a-col",
        { attrs: { md: 12, sm: 24 } },
        [
          _c(
            "a-tabs",
            { attrs: { defaultActiveKey: "1" } },
            [
              _c(
                "a-tab-pane",
                { key: "1", attrs: { tab: "基本信息" } },
                [
                  _vm.selectedKeys.length > 0
                    ? _c(
                        "a-card",
                        { attrs: { bordered: false } },
                        [
                          _c(
                            "a-form",
                            { attrs: { form: _vm.form } },
                            [
                              _c(
                                "a-form-item",
                                {
                                  attrs: {
                                    labelCol: _vm.labelCol,
                                    wrapperCol: _vm.wrapperCol,
                                    label: "机构名称",
                                  },
                                },
                                [
                                  _c("a-input", {
                                    directives: [
                                      {
                                        name: "decorator",
                                        rawName: "v-decorator",
                                        value: [
                                          "departName",
                                          _vm.validatorRules.departName,
                                        ],
                                        expression:
                                          "['departName', validatorRules.departName ]",
                                      },
                                    ],
                                    attrs: {
                                      placeholder: "请输入机构/部门名称",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "a-form-item",
                                {
                                  attrs: {
                                    labelCol: _vm.labelCol,
                                    wrapperCol: _vm.wrapperCol,
                                    label: "上级部门",
                                  },
                                },
                                [
                                  _c("a-tree-select", {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      dropdownStyle: {
                                        maxHeight: "200px",
                                        overflow: "auto",
                                      },
                                      treeData: _vm.treeData,
                                      disabled: _vm.disable,
                                      placeholder: "无",
                                    },
                                    model: {
                                      value: _vm.model.parentId,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.model, "parentId", $$v)
                                      },
                                      expression: "model.parentId",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "a-form-item",
                                {
                                  attrs: {
                                    labelCol: _vm.labelCol,
                                    wrapperCol: _vm.wrapperCol,
                                    label: "机构编码",
                                  },
                                },
                                [
                                  _c("a-input", {
                                    directives: [
                                      {
                                        name: "decorator",
                                        rawName: "v-decorator",
                                        value: [
                                          "orgCode",
                                          _vm.validatorRules.orgCode,
                                        ],
                                        expression:
                                          "['orgCode', validatorRules.orgCode ]",
                                      },
                                    ],
                                    attrs: {
                                      disabled: "",
                                      placeholder: "请输入机构编码",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "a-form-item",
                                {
                                  attrs: {
                                    labelCol: _vm.labelCol,
                                    wrapperCol: _vm.wrapperCol,
                                    label: "机构类型",
                                  },
                                },
                                [
                                  _vm.orgCategoryDisabled
                                    ? [
                                        _c(
                                          "a-radio-group",
                                          {
                                            directives: [
                                              {
                                                name: "decorator",
                                                rawName: "v-decorator",
                                                value: [
                                                  "orgCategory",
                                                  _vm.validatorRules
                                                    .orgCategory,
                                                ],
                                                expression:
                                                  "['orgCategory',validatorRules.orgCategory]",
                                              },
                                            ],
                                            attrs: {
                                              placeholder: "请选择机构类型",
                                            },
                                          },
                                          [
                                            _c(
                                              "a-radio",
                                              { attrs: { value: "1" } },
                                              [
                                                _vm._v(
                                                  "\n                    公司\n                  "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    : [
                                        _c(
                                          "a-radio-group",
                                          {
                                            directives: [
                                              {
                                                name: "decorator",
                                                rawName: "v-decorator",
                                                value: [
                                                  "orgCategory",
                                                  _vm.validatorRules
                                                    .orgCategory,
                                                ],
                                                expression:
                                                  "['orgCategory',validatorRules.orgCategory]",
                                              },
                                            ],
                                            attrs: {
                                              placeholder: "请选择机构类型",
                                            },
                                          },
                                          [
                                            _c(
                                              "a-radio",
                                              { attrs: { value: "2" } },
                                              [
                                                _vm._v(
                                                  "\n                    部门\n                  "
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "a-radio",
                                              { attrs: { value: "3" } },
                                              [
                                                _vm._v(
                                                  "\n                    岗位\n                  "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                ],
                                2
                              ),
                              _c(
                                "a-form-item",
                                {
                                  attrs: {
                                    labelCol: _vm.labelCol,
                                    wrapperCol: _vm.wrapperCol,
                                    label: "排序",
                                  },
                                },
                                [
                                  _c("a-input-number", {
                                    directives: [
                                      {
                                        name: "decorator",
                                        rawName: "v-decorator",
                                        value: [
                                          "departOrder",
                                          { initialValue: 0 },
                                        ],
                                        expression:
                                          "[ 'departOrder',{'initialValue':0}]",
                                      },
                                    ],
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "a-form-item",
                                {
                                  attrs: {
                                    labelCol: _vm.labelCol,
                                    wrapperCol: _vm.wrapperCol,
                                    label: "手机号",
                                  },
                                },
                                [
                                  _c("a-input", {
                                    directives: [
                                      {
                                        name: "decorator",
                                        rawName: "v-decorator",
                                        value: ["mobile", { initialValue: "" }],
                                        expression:
                                          "['mobile', {'initialValue':''}]",
                                      },
                                    ],
                                    attrs: { placeholder: "请输入手机号" },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "a-form-item",
                                {
                                  attrs: {
                                    labelCol: _vm.labelCol,
                                    wrapperCol: _vm.wrapperCol,
                                    label: "地址",
                                  },
                                },
                                [
                                  _c("a-input", {
                                    directives: [
                                      {
                                        name: "decorator",
                                        rawName: "v-decorator",
                                        value: [
                                          "address",
                                          { initialValue: "" },
                                        ],
                                        expression:
                                          "['address', {'initialValue':''}]",
                                      },
                                    ],
                                    attrs: { placeholder: "请输入地址" },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "a-form-item",
                                {
                                  attrs: {
                                    labelCol: _vm.labelCol,
                                    wrapperCol: _vm.wrapperCol,
                                    label: "备注",
                                  },
                                },
                                [
                                  _c("a-textarea", {
                                    directives: [
                                      {
                                        name: "decorator",
                                        rawName: "v-decorator",
                                        value: ["memo", { initialValue: "" }],
                                        expression:
                                          "['memo', {'initialValue':''}]",
                                      },
                                    ],
                                    attrs: { placeholder: "请输入备注" },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "anty-form-btn" },
                            [
                              _c(
                                "a-button",
                                {
                                  attrs: {
                                    type: "default",
                                    htmlType: "button",
                                    icon: "sync",
                                  },
                                  on: { click: _vm.emptyCurrForm },
                                },
                                [_vm._v("重置")]
                              ),
                              _c(
                                "a-button",
                                {
                                  attrs: {
                                    type: "primary",
                                    htmlType: "button",
                                    icon: "form",
                                  },
                                  on: { click: _vm.submitCurrForm },
                                },
                                [_vm._v("保存")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _c(
                        "a-card",
                        [
                          _c("a-empty", [
                            _c(
                              "span",
                              {
                                attrs: { slot: "description" },
                                slot: "description",
                              },
                              [_vm._v(" 请先选择一个部门! ")]
                            ),
                          ]),
                        ],
                        1
                      ),
                ],
                1
              ),
              _c(
                "a-tab-pane",
                { key: "2", attrs: { tab: "部门权限", forceRender: "" } },
                [_c("depart-auth-modal", { ref: "departAuth" })],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("depart-modal", { ref: "departModal", on: { ok: _vm.loadTree } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }